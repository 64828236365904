import http from '@/api/http'

import { TGetSpecialNeedsResponse } from './types'

export const getSpecialNeeds = async () => {
  const response = await http.get<TGetSpecialNeedsResponse>(
    '/users/special_need/'
  )
  return response.data
}
