import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query'

import {
  getProfileNames,
  getUser,
  getUsers,
  getUsersPaginated,
} from './services'
import {
  TGetUserParams,
  TGetUserResponse,
  TGetUsersResponse,
  TGetUsersPaginatedParams,
  TGetUsersPaginatedResponse,
  TGetUserProfileNameResponses,
} from './types'

export const useQueryUsers = (options?: UseQueryOptions<TGetUsersResponse>) => {
  const queryFunction = () => getUsers()

  return useQuery<TGetUsersResponse>(
    useQueryUsers.getFullQueryKey(),
    queryFunction,
    options
  )
}

useQueryUsers.getFullQueryKey = (): QueryKey => ['users']

useQueryUsers.queryKey = ['users']

export const useQueryUsersPaginated = (
  input: TGetUsersPaginatedParams,
  options?: UseQueryOptions<TGetUsersPaginatedResponse>
) => {
  const queryFunction = () => getUsersPaginated(input)

  return useQuery<TGetUsersPaginatedResponse>(
    useQueryUsersPaginated.getFullQueryKey(input),
    queryFunction,
    options
  )
}

useQueryUsersPaginated.getFullQueryKey = (
  input: TGetUsersPaginatedParams
): QueryKey => ['users_paginated', input]

useQueryUsersPaginated.queryKey = (
  input: TGetUsersPaginatedParams
): QueryKey => ['users_paginated', input]

export const useQueryUser = (
  input: TGetUserParams,
  options?: UseQueryOptions<TGetUserResponse>
) => {
  const queryFunction = () => getUser(input)

  return useQuery<TGetUserResponse>(
    useQueryUser.getFullQueryKey(),
    queryFunction,
    options
  )
}

useQueryUser.getFullQueryKey = (): QueryKey => ['User']

useQueryUser.queryKey = ['User']

export const useQueryProfileNames = (
  options?: UseQueryOptions<TGetUserProfileNameResponses>
) => {
  const queryFunction = () => getProfileNames()

  return useQuery<TGetUserProfileNameResponses>(
    useQueryProfileNames.getFullQueryKey(),
    queryFunction,
    options
  )
}

useQueryProfileNames.getFullQueryKey = (): QueryKey => ['profileNames']

useQueryProfileNames.queryKey = ['profileNames']
