interface IParseCSVProps {
  file: File
  requiredColumns?: Array<string>
}

/**
 * @description Function to parse a CSV file and convert it into an array of objects.
 * @param {IParseCSVProps} props - An object containing the CSV file and optional required columns.
 * @param {File} props.file - The CSV file to parse.
 * @param {Array<string>} [props.requiredColumns=[]] - An optional array of column names that must be present in the CSV file.
 * @returns {Promise<Array<Record<string, string>>>} - A promise that resolves with an array of objects representing the CSV data.
 */
export const parseCSV = ({
  file,
  requiredColumns = [],
}: IParseCSVProps): Promise<Array<Record<string, string>>> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = e => {
      const csv = e.target?.result as string
      const rows = csv.split('\n')
      const headers = parseCSVLine(rows[0]).map(h =>
        h
          .trim()
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
      )

      const headerMap = new Map<string, number>()
      const uniqueHeaders = headers.map(header => {
        const count = headerMap.get(header) || 0
        headerMap.set(header, count + 1)
        return count > 0 ? `${header}_${count}` : header
      })

      for (const column of requiredColumns) {
        const normalizedColumn = column
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
        if (!uniqueHeaders.includes(normalizedColumn)) {
          reject(
            new Error(
              `O arquivo ${file.name} não contém a coluna obrigatória: ${column}`
            )
          )
          return
        }
      }

      const data = rows.slice(1).map(row => {
        const values = parseCSVLine(row)
        return uniqueHeaders.reduce((acc, header, i) => {
          acc[header] = values[i]
          return acc
        }, {} as Record<string, string>)
      })

      if (
        data.length &&
        Object.values(data[data.length - 1]).every(v => v === '')
      ) {
        data.pop()
      }

      resolve(data)
    }
    reader.onerror = e => {
      reject(e)
    }
    reader.readAsText(file)
  })
}

/**
 * @description Function to parse a single line of CSV and handle quoted fields.
 * @param {string} line - The CSV line to parse.
 * @returns {Array<string>} - An array of fields from the CSV line.
 */
const parseCSVLine = (line: string): Array<string> => {
  const result: Array<string> = []
  let currentField = ''
  let insideQuotes = false

  for (let i = 0; i < line.length; i++) {
    const char = line[i]

    if (char === '"' && (i === 0 || line[i - 1] !== '\\')) {
      insideQuotes = !insideQuotes
    } else if (char === ',' && !insideQuotes) {
      result.push(currentField.trim())
      currentField = ''
    } else {
      currentField += char
    }
  }
  result.push(currentField.trim())

  return result.map(field => field.replace(/^"|"$/g, ''))
}
